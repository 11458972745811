import { DestroyRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { BehaviorSubject, catchError, filter, from, interval, of, switchMap, take, tap } from 'rxjs';

import { ErrorService } from '@services/error.service';
import { SignoutService } from '@services/signout.service';

import { UserState } from '@state/user.state';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  public updateNow$ = new BehaviorSubject<boolean>(false);
  public updateAvailable = false;

  constructor(
    private destroyRef: DestroyRef,
    private error: ErrorService,
    private signout: SignoutService,
    private updates: SwUpdate,
    private userState: UserState,
  ) { }

  public initUpdates(): void {
    if (this.updates.isEnabled) {
      // Check once immediately...
      from(this.updates.checkForUpdate())
        .pipe(
          take(1),
          this.signout.takeUntil(this.destroyRef),
          tap(hasUpdate => {
            if (hasUpdate) {
              this.updateAvailable = true;
              this.updateNow$.next(true);
            }
          }),
          catchError(error => {
            console.error(`update.initUpdates: Failed immediate check for updates, ${this.error.toStr(error)}`);
            return of(false);
          }),
        )
        .subscribe();

      // ...and then every minutes
      const every2minutes$ = interval(60 * 1000);
      every2minutes$
        .pipe(
          this.signout.takeUntil(this.destroyRef),
          switchMap(() => from(this.updates.checkForUpdate()).pipe(
            tap(hasUpdate => {
              if (hasUpdate) {
                this.updateAvailable = true;
                this.updateNow$.next(true);
              }
            }),
            catchError(error => {
              console.error(`update.initUpdates: Failed recurring check for updates, ${this.error.toStr(error)}`);
              return of(false);
            }),
          )))
        .subscribe();

      this.updates.versionUpdates
        .pipe(
          this.signout.takeUntil(this.destroyRef),
          filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        )
        .subscribe(() => {
          this.updateAvailable = true;
          this.updateNow$.next(true);
        });
    }

    // If the user is in an unrecoverable state, force a reload
    this.updates.unrecoverable
      .pipe(this.signout.takeUntil(this.destroyRef))
      .subscribe(async error => {
        console.error(`update.initUpdates: Unrecoverable error for ${this.userState.user.email}: ${error.reason}`);
        await this.reload();
      });
  }

  // Method to force update by unregistering and re-registering service worker
  public async forceUpdate(sendAlert = true): Promise<void> {
    if (sendAlert) console.error(`update.forceUpdate: Requested by ${this.userState.user.email}...`);
    try {
      // Unregister the existing service worker if one exists
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }

      // Clear the service worker cache
      if ('caches' in window) {
        const keys = await caches.keys();
        for (const key of keys) {
          await caches.delete(key);
        }
      }

      // Reload the app
      window.location.reload();
    } catch (error) {
      console.error(`update.forceUpdate for ${this.userState.user.email}: ${this.error.toStr(error)}`);
    }
  }

  public async reload(): Promise<void> {
    window.location.reload();
  }
}
